<template>
  <div class="container">

    <ConfirmDialog group="cancelApprovalDialog"></ConfirmDialog>
    <EducationCurriculumCreditTimeNormModal/>

    <div class="education-curriculum-info mt-4">
      <h4 style="text-align: center">Дисциплины РУП-а</h4>
      <div class="text-center">
        <b>{{urlEducationSpecialityName}}</b> (учебный год: {{urlAcademicYearName}}, год набора: {{urlAdmissionYear}})
      </div>

      <div class="my-4">
        <div class="row fw-bold border py-2">
          <div class="col-3">Название</div>
          <div class="col-3">Наименование дисциплины</div>
          <div class="col-2">Язык</div>
          <div class="col-1">Семестр</div>
          <div class="col-1">Кредит</div>
          <div class="col-1">Часы</div>
          <div class="col-1">Кол.во студ.</div>
        </div>
        <div v-for="(module, moduleKey) in educationCurriculum_form.formationEducationProgramInfos.rup" :key="moduleKey">
          <div class="row border py-2">
            <div class="col-9 fw-bold">{{module.module_name}}</div>
            <div class="col-1 font-size-14px">{{module.credit.count}} ({{Math.ceil(module.credit.percent)}}&#37;)</div>
            <div class="col-1 font-size-14px">{{module.hours}}</div>
            <div class="col-1 font-size-14px">{{module.student.count}} ({{Math.ceil(module.student.percent)}}&#37;)</div>
          </div>
          <div v-for="(cycle, cycleKey) in module.cycles" :key="cycleKey">
            <div class="row border py-2">
              <div class="col-9 ps-3">{{cycle.cycle_name}}</div>
              <div class="col-1 font-size-14px">{{cycle.credits_hours.credit.credit_count}} ({{Math.ceil(cycle.credits_hours.credit.percent)}}&#37;)</div>
              <div class="col-1 font-size-14px">{{cycle.credits_hours.hour}}</div>
              <div class="col-1 font-size-14px">{{cycle.credits_hours.students.count}} ({{Math.ceil(cycle.credits_hours.students.percent)}}&#37;)</div>
            </div>
            <div v-for="(component, componentKey) in cycle.components" :key="componentKey">
              <div class="row border py-2">
                <div class="col-9 ps-4">{{component.component_name}}</div>
                <div class="col-1 font-size-14px">{{component.credits_hours.credit}}</div>
                <div class="col-1 font-size-14px">{{component.credits_hours.hour}}</div>
                <div class="col-1 font-size-14px">{{component.credits_hours.students.count}} ({{Math.ceil(component.credits_hours.students.percent)}}&#37;)</div>
              </div>
              <div v-for="(discipline, disciplineKey) in component.disciplines" :key="disciplineKey">
                <div class="row border py-2">
                  <div class="col-1 fw-bold">{{discipline.index}}</div>
                  <div class="col-2">{{discipline.discipline_code}}</div>
                  <div class="col-3">{{discipline.discipline_name}}</div>
                  <div class="col-2">{{discipline.discipline_language}}</div>
                  <div class="col-1">{{discipline.semester}}</div>
                  <div class="col-1"><a href="#" data-bs-toggle="modal" data-bs-target="#creditTimeNormModal"
                    @click="openCreditTimeNormModal(discipline.credits_hours.credit)">{{discipline.credits_hours.credit}}</a></div>
                  <div class="col-1">{{discipline.credits_hours.hour}}</div>
                  <div class="col-1 font-size-14px">{{discipline.credits_hours.students.count}} ({{Math.ceil(Number(discipline.credits_hours.students.percent))}}&#37;)</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <table class="table table-striped table-bordered border-dark">
        <thead>
        <tr>
          <th scope="col">Количество студентов</th>
          <th scope="col">Сумма кредитов за осенний семестр</th>
          <th scope="col">Сумма кредитов за весенний семестр</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(sumCredit,sumCreditIndex) in educationCurriculum_form.formationEducationProgramInfos.sum_credits" :key="sumCreditIndex">
          <td>{{sumCredit.students_count}}</td>
          <td>{{sumCredit.autumn}}</td>
          <td>{{sumCredit.spring}}</td>
        </tr>
        </tbody>
      </table>

      <div class="text-center my-4" v-if="isOfficeRegistration">
        <button type="button" class="btn btn-primary" @click="saveFormationApproved" :disabled="btnSaveFormationApprovedStatus || educationCurriculum_form.educationCurriculumConfirmStatus == 1">
                                <span v-if="btnSaveFormationApprovedStatus" class="spinner-grow spinner-grow-sm" role="status"
                                      aria-hidden="true"></span>
          {{ btnSaveFormationApprovedStatus ? 'Утверждение' : 'Утвердить' }}
        </button>

        <button type="button" class="btn btn-danger ms-2" @click="confirmCancelApproval" :disabled="educationCurriculum_form.educationCurriculumConfirmStatus == null">
          Отменить утверждение
        </button>
      </div>
    </div>
  </div>


</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import EducationCurriculumCreditTimeNormModal
  from '@/components/education-programme/EducationCurriculumCreditTimeNormModal.vue'
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

export default {
  name: "EducationCurriculumInfo",
  components: {
    EducationCurriculumCreditTimeNormModal,
  },


  data() {
    return {
      urlEducationProgramId: this.$route.query.education_program_id || 0,
      urlEducationSpecialityName: this.$route.query.education_speciality_name || 0,
      urlStudyCourse: this.$route.query.study_course || 0,
      urlAcademicYearId: this.$route.query.academic_year_id || 0,
      urlAcademicYearName: this.$route.query.academic_year_name || 0,
      urlAdmissionYear: this.$route.query.admission_year || 0,
      btnSaveFormationApprovedStatus: false,

      modules: [
        {
          name: 'Модуль: Әлеуметтік гуманитарлы',
          student_count: 54,
          hour: 240,
          credit: 55,
          cycles: [
            {
              name: 'Цикл ООД',
              credit: 5,
              hour: 150,
              student_count: 12,
              components: [
                {
                  name: 'Обязательный компонент',
                  disciplines: [
                    {
                      name: 'Психология'
                    },
                    {
                      name: 'Алгебра және геометрия'
                    },
                  ]
                },
                {
                  name: 'Компонент по выбору',
                  disciplines: [
                    {
                      name: '123'
                    },
                    {
                      name: '456'
                    },
                  ]
                },
                {
                  name: 'Вузовский компонент',
                  disciplines: [
                    {
                      name: '789'
                    },
                    {
                      name: '456456456'
                    },
                  ]
                },
              ]
            },
            {
              name: 'Цикл БД',
              credit: 15,
              hour: 90,
              student_count: 14,
              components: [
                {
                  name: 'Обязательный компонент'
                },
                {
                  name: 'Компонент по выбору'
                },
                {
                  name: 'Вузовский компонент'
                },
              ]
            },
            {
              name: 'Цикл ПД',
              credit: 25,
              hour: 30,
              student_count: 16,
              components: [
                {
                  name: 'ОК'
                },
                {
                  name: 'КВ'
                },
                {
                  name: 'ВК'
                },
              ]
            }
          ]
        },
        {
          name: 'Модуль: Ақпараттық-математикалық',
          student_count: 66,
          hour: 242,
          credit: 45,
          cycles: [
            {
              name: 'Цикл ООД',
              components: [
                {
                  name: 'ОК'
                },
                {
                  name: 'КВ'
                },
                {
                  name: 'ВК'
                },
              ]
            },
            {
              name: 'Цикл БД',
              components: [
                {
                  name: 'ОК'
                },
                {
                  name: 'КВ'
                },
                {
                  name: 'ВК'
                },
              ]
            },
            {
              name: 'Цикл ПД',
              components: [
                {
                  name: 'ОК'
                },
                {
                  name: 'КВ'
                },
                {
                  name: 'ВК'
                },
              ]
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('educationCurriculum', ['educationCurriculum_form']),
    ...mapState('gosoTemplates', ['gosoTemplate_form']),
    isOfficeRegistration() {
      return decryptCheckRole('officeRegistration') || decryptCheckRole('admin')
    },
  },

  methods: {
    ...mapActions('gosoTemplates', ['GET_GOSO_CYCLE_NAME', 'GET_GOSO_COMPONENTS_NAME']),
    ...mapActions('educationCurriculum', ['GET_EDUCATION_PROGRAM', 'GET_EDUCATION_PROGRAM_DATA', 'GET_FORMATION_EDUCATION_PROGRAM_DATA',
      'GET_STUDY_FORM_NAMES', 'GET_SP_ACADEMIC_YEAR', 'POST_FORMATION_EDUCATION_PROGRAM_APPROVED',
      'DELETE_FORMATION_EDUCATION_PROGRAM_APPROVED']),
    ...mapMutations('educationCurriculum', ['SET_CREDIT_TIME_NORM_MODAL_VALUE']),
    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },

    async openCreditTimeNormModal(value) {
      this.SET_CREDIT_TIME_NORM_MODAL_VALUE(value)
    },

    async saveFormationApproved() {
      this.btnSaveFormationApprovedStatus = true

      try {

        const resPost = await this.POST_FORMATION_EDUCATION_PROGRAM_APPROVED({academic_year_id: this.urlAcademicYearId, study_course: this.urlStudyCourse, education_program_id: this.urlEducationProgramId})

        if (resPost ) {
          await this.GET_FORMATION_EDUCATION_PROGRAM_DATA({
            education_program_id: this.urlEducationProgramId,
            study_course: this.urlStudyCourse,
            academic_year_id: this.urlAcademicYearId
          })
          this.$message({text: 'Утверждено'})
        }
      } catch (e) {
        this.$error({title: `Ошибка ${e.message}`, text: JSON.stringify(e?.response?.data?.errors)})
      }

      this.btnSaveFormationApprovedStatus = false
    },

    confirmCancelApproval() {
      this.$confirm.require({
        group: 'cancelApprovalDialog',
        message: 'Вы точно хотите отменить утверждение?',
        header: 'Отменить утверждение',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          this.cancelApproval()
        },
        reject: () => {}
      });
    },

    async cancelApproval(){
      const res = await this.DELETE_FORMATION_EDUCATION_PROGRAM_APPROVED({academic_year_id: this.urlAcademicYearId, study_course: this.urlStudyCourse, education_program_id: this.urlEducationProgramId})
      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA({
          education_program_id: this.urlEducationProgramId,
          study_course: this.urlStudyCourse,
          academic_year_id: this.urlAcademicYearId
        })
        this.$message({text: 'Утверждение отменено'})
      }
    }

  },
  async mounted() {
    console.log(this.urlStudyCourse, 'urlStudyCourse')
    await this.GET_FORMATION_EDUCATION_PROGRAM_DATA({
      education_program_id: this.urlEducationProgramId,
      study_course: this.urlStudyCourse,
      academic_year_id: this.urlAcademicYearId
    })
    await this.GET_SP_ACADEMIC_YEAR()
    await this.GET_STUDY_FORM_NAMES()
    await this.GET_GOSO_CYCLE_NAME()
    await this.GET_GOSO_COMPONENTS_NAME()
  }
}
</script>

<style scoped>
.font-size-14px{
  font-size: 14px;
}

</style>
