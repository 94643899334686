<template>
  <!--  Modal -->
  <div class="modal fade" id="creditTimeNormModal" tabindex="-1"
       aria-labelledby="creditTimeNormModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="creditTimeNormModalLabel">Норма времени по видам учебной работы</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4">

<!--            {{creditTimeNormTemplate_form.creditTimeNorm[educationCurriculum_form.creditTimeNormModalValue] }}-->

            <div class="form-row">

              <div class="card mt-4">
                <div class="card-body">
                  <div class="mt-4 border p-2"
                       v-for="(creditTimeNormTemplateInfo, index) in creditTimeNormTemplate_form.creditTimeNorm[educationCurriculum_form.creditTimeNormModalValue]"
                       :key="index">

                    <div class="d-flex flex-row-reverse">
                      <b>Количество часов: {{creditTimeNormTemplateInfo.sum}}</b>
                    </div>
                      <p class="card-text"><b>Вид учебной работы:
                        {{creditTimeNormTemplate_form.educationWorkTypes.length?creditTimeNormTemplate_form.educationWorkTypes.find(i=>i.id==creditTimeNormTemplateInfo.sp_education_work_id).name_ru:''}}</b></p>


                    <div class="row mt-4">
                      <template v-for="(week, weekKey) of creditTimeNormTemplateInfo" :key="weekKey">
                        <div class="col-md-4 mt-1 mb-2" v-if="weekKey.startsWith('w_')">
                          <label :for="weekKey">{{ 'Неделя ' + weekKey.substr(2) }}</label>
                          <input :id="weekKey" type="number" class="form-control" min="1" max="3"
                                 :value="week"
                                 @change="changeWeekHour(creditTimeNormTemplateInfo, weekKey, $event)" disabled>
                        </div>
                      </template>
                    </div>


                  </div>


                </div>
              </div>


              <div class="text-center mt-2" v-if="creditTimeNormTemplate_form.newCreditTimeNormTemplateInfos.length">
                <button class="btn btn-primary" @click="saveCreditTimeNorm">Сохранить</button>
              </div>

            </div>

          </div>


        </div>
        <div class="modal-footer">
<!--          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"-->
<!--                  @click="updateDisciplineFields(UPDATE_ITEM.id)">-->
<!--            Обновить-->
<!--          </button>-->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "EducationCurriculumCreditTimeNormModal",

  data() {
    return {

    }
  },
  computed: {
    ...mapState('educationCurriculum', ['educationCurriculum_form']),
    ...mapState('creditTimeNormTemplate', ['creditTimeNormTemplate_form']),
  },

  methods: {
    //...mapActions('educationCurriculum', ['GET_EDUCATION_PROGRAM_DATA','GET_FORMATION_EDUCATION_PROGRAM_DATA']),
    ...mapActions('creditTimeNormTemplate', ['GET_SP_EDUCATION_WORK_TYPE', 'GET_CREDIT_TIME_NORM']),
    ...mapMutations('educationCurriculum', ['SET_CREDIT_TIME_NORM_MODAL_VALUE']),
  },
  async mounted() {
    await this.GET_CREDIT_TIME_NORM()
    await this.GET_SP_EDUCATION_WORK_TYPE()
    console.log('dada', this.educationCurriculum_form.creditTimeNormModalValue)
  }
}
</script>

<style scoped>

</style>